import "../../_core/scripts/specific/layout/index";
import {initHeader} from "../../components/header/header";
import {initFooter} from "../../components/footer/footer";
import {initNewsletter} from "../../components/newsletter/newsletter";

if (console !== undefined) {
    console.log("Initialize error page ;-)");
}

initHeader(false);
initFooter();
initNewsletter();